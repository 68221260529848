import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import Seo from '../components/Seo';
import { Inner } from 'styles/structure';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import Button from 'components/_ui/Button';
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import { AuthorPage } from '../templates/author'
import { BioPage } from '../templates/bio'
import { CategoryPage } from '../templates/category'
import { PostPage } from '../templates/post'
import { ResourcePage } from '../templates/resource'
import { SimplePage } from '../templates/simple-page'
import { TopicPage } from '../templates/topic'
import { ProgramArmPage } from '../templates/program-arm'
import { ProgramPage } from '../templates/program'

const NotFoundContainer = styled(Inner)`
  height: 48vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  header {
    margin-bottom: 4rem;

    h1,
    h3 {
      color: ${colors.grey800};
      padding-bottom: 1rem;
    }
  }
`;

const NotFoundPage = () => (
  <Layout>
    <NotFoundContainer>
      <div>
        <Seo title="Page not found" />

        <header>
          <h1>404</h1>
          <h3>Page Not Found</h3>
        </header>

        <Button as={Link} to="/">
          Go Home
        </Button>
      </div>
    </NotFoundContainer>
  </Layout>
);

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    author: AuthorPage,
    prismicAuthor: AuthorPage,
    bio: BioPage,
    prismicBio: BioPage,
    category: CategoryPage,
    prismicCategory: CategoryPage,
    post: PostPage,
    prismicPost: PostPage,
    resource: ResourcePage,
    prismicResource: ResourcePage,
    simple_page: SimplePage,
    prismicSimplePage: SimplePage,
    topic: TopicPage,
    prismicTopic: TopicPage,
    program_arm: ProgramArmPage,
    prismicProgramArm: ProgramArmPage,
    program: ProgramPage,
    prismicProgram: ProgramPage,
  },
})
